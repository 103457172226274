export default {
	data() {
		return {};
	},
	methods: {
		gaEvent(action = 'defaultAction', label = 'defaultLabel', value = 1, category = 'marketplace') {
			if (this.$ga == null) return false;
			this.$ga.event(category, action, label, value);
		}
	},
};