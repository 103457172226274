<template>
	<div>
		<el-dialog :title="$t('feedback.menu-item')" :show-close="false" :close-on-press-escape="false"
			:modal-append-to-body="false" :modal="!canCreateCanvas"
			:class="{'canvasModal draggable-dialog': canCreateCanvas}" class="feedbackModal noselect"
			@closed="destroy()" :visible.sync="dialogVisible" :width="customModalSize" :close-on-click-modal="false"
			:append-to-body="true" v-draggable>
			<div>
				<template v-if="canCreateCanvas">
					<fullfeedback :step.sync="activeStep" ref="message" v-model="message"></fullfeedback>
				</template>
				<template v-else>
					<basicfeedback></basicfeedback>
				</template>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button class="btn-onclick-destroy" @click="destroy()" style="float: left">{{$t('general.cancel')}}</el-button>
				<el-button class="btn-onclick-prevstep" @click="prevStep" v-if="activeStep > 0 && canCreateCanvas" type="primary">
					{{$t('feedback.prev')}}</el-button>
				<el-button class="btn-onclick-nextstep" @click="nextStep" v-if="activeStep < 2 && canCreateCanvas" type="primary">
					{{$t('feedback.next')}}</el-button>
				<el-button class="btn-onclick-send-feedback" v-else type="primary" @click="sendFeedback">{{$t('feedback.send')}}</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import DlgDraggable from '@/directives/Draggable';
	import Vue from 'vue';
	import 'javascript-detect-element-resize';
	Vue.use(DlgDraggable);

	const FullFeedback = () =>
		import('./FullFeedback');
	const BasicFeedback = () =>
		import('./BasicFeedback');
	import analytics from '@/mixins/analytics';

	import {
		EventBus
	} from '@/utils/Bus.js';
	export default {
		name: 'feedback',
		components: {
			'fullfeedback': FullFeedback,
			'basicfeedback': BasicFeedback
		},
		mixins: [analytics],
		data() {
			return {
				dialogVisible: true,
				message: '',
				isCanvasActive: false,
				activeStep: 0
			};
		},
		methods: {
			nextStep() {
				if (this.activeStep === 1 && this.message.length < 5) {
					this.sbMsg({
						message: this.$t('feedback.desc-error'),
						type: 'error'
					});
					return false;
				}
				this.activeStep++;
			},
			prevStep() {
				this.activeStep--;
			},
			destroy() {
				this.$store.dispatch('app/ToggleFeedBackModal', false);
				this.$destroy();
			},
			sendFeedback() {
				if (this.canCreateCanvas) {
					this.gaEvent('support_send', 'support', 1);
					EventBus.$emit('sendfullfeedback');
				} else {
					this.gaEvent('support_send', 'support', 0);
					EventBus.$emit('sendbasicfeedback');
				}
			},
		},
		computed: {
			canCreateCanvas() {
				return !!window.HTMLCanvasElement && !this.isDeviceMobile;
			},
			isDeviceMobile() {
				return this.$store.state.app.device === 'mobile';
			},
			customModalSize() {
				return this.isDeviceMobile ? '80%' : '35%';
			}
		},
		mounted() {
			EventBus.$on('destroyFeedback', () => {
				this.destroy();
			});
		},
		created() {

		},
		beforeDestroy() {
			const elDialog = document.getElementsByClassName('.el-dialog');
			if (elDialog.length > 0) {
				removeResizeListener(elDialog[0], () => {});
			}
			EventBus.$off();
		},
	};
</script>