import 'javascript-detect-element-resize';
const vueElementDialogDraggable = {};
vueElementDialogDraggable.install = (Vue, options) => {
    Vue.directive('draggable', {
        bind(el, binding, vnode) {
            const dlg = el.getElementsByClassName('el-dialog')[0];
            const title = el.getElementsByClassName('el-dialog__title')[0];
            title.style.userSelect = 'none';
            title.style['-ms-user-select'] = 'none';
            title.style['-moz-user-select'] = 'none';
            title.style.cursor = 'default';

            let width;
            let height;
            let outerWidth = dlg.offsetWidth;
            let outerHeight = dlg.offsetHeight;
            let offset = dlg.getBoundingClientRect();
            let posY;
            let posX;
            let _top;
            let _left;
            let _bottom;
            let _right;

            const move = (e) => {
                _top = e.pageY + posY - outerHeight;
                _left = e.pageX + posX - outerWidth;
                _bottom = outerHeight - e.pageY;
                _right = outerWidth - e.pageX;
                if (_left < 0) _left = 0;
                if (_top < 0) _top = 0;

                if (_right > width) {
                    _left = width - outerWidth;
                }
                if (_left > width - outerWidth) {
                    _left = width - outerWidth;
                }
                if (_bottom > height) {
                    _top = height - outerHeight;
                }
                if (_top > height - outerHeight) {
                    _top = height - outerHeight;
                }

                dlg.style.marginLeft = '0px';
                dlg.style.marginTop = '0px';
                dlg.style.left = _left + 'px';
                dlg.style.top = _top + 'px';
                dlg.style.bottom = 'auto';
            };

            const up = () => {
                removeEventListener('mousemove', move);
                removeEventListener('mouseup', up);
                addResizeListener(dlg, (o, e) => {
                    outerWidth = dlg.offsetWidth;
                    outerHeight = dlg.offsetHeight;
                    offset = dlg.getBoundingClientRect();

                    if (_left < 0) _left = 0;
                    if (_top < 0) _top = 0;

                    if (_right > width) {
                        _left = width - outerWidth;
                    }
                    if (_left > width - outerWidth) {
                        _left = width - outerWidth;
                    }
                    if (_bottom > height) {
                        _top = height - outerHeight;
                    }
                    if (_top > height - outerHeight) {
                        _top = height - outerHeight;
                    }

                    dlg.style.marginLeft = '0px';
                    dlg.style.marginTop = '0px';
                    dlg.style.left = _left + 'px';
                    dlg.style.top = _top + 'px';
                    dlg.style.bottom = 'auto';
                });
            };

            const down = (e) => {
                width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

                height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

                outerWidth = dlg.offsetWidth;
                outerHeight = dlg.offsetHeight;
                offset = dlg.getBoundingClientRect();

                posY = offset.top + outerHeight - e.pageY;
                posX = offset.left + outerWidth - e.pageX;

                dlg.offsetX = e.pageX - dlg.offsetLeft;
                dlg.offsetY = e.pageY - dlg.offsetTop;

                addEventListener('mousemove', move);
                addEventListener('mouseup', up);
            };
            const header = el.getElementsByClassName('el-dialog__header')[0];
            header.addEventListener('mousedown', down);
        }
    });
};

export default vueElementDialogDraggable;
